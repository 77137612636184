@font-face {
  font-family: "yu-gothic";
  src: local("yu-gothic"), url(./fonts/yu-gothic.ttf) format("truetype");
}
@font-face {
  font-family: "yu-gothic-bold";
  src: local("yu-gothic-bold"), url(./fonts/yugothib.ttf) format("truetype");
}

body {
  height: 100%;
  margin: 0;
  font-family: "yu-gothic-pr6n", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: "yu-gothic", sans-serif;
  font-weight: bold;
}
:root {
  height: 100%;
  --bgInput: #ffffcc;
  --bgPlanned: #83eaf5;
  --bgDisabled: #ccc;
  --fontSize1: 14px;
  --fontSize2: 16px;
  --fontSize3: 18px;
  --fontSize4: 20px;
  --fontSize5: 22px;
  --fontSize6: 23px;
  --fontSize7: 26px;
  --fontSize8: 28px;
  --fontSize9: 30px;
  --fontSize10: 32px;
  --fontSize11: 34px;
  --bgLabel: #e7e6e6;
  --bgManage: #ffa7ff;
  --bgNG: #ff0000;
  --bgOK: #83eaf5;
  --bgCaculation: #e1efd8;
  --bgDisable: #d8d8d8;
  --bgOrange: #f7c4a1;
  --bgLightBlue: #83eaf5;
  --bgLightGreen: #e1efd8;
  --lightGray: #e7e6e6;
  --heightCircleInput: 252px;
  /* heightCircleInput = heightItemFull*3 + 22px *3 (22px because height label is 22px) */
  --heightCircle: 190px;
  /* heightCircle = heightCircleInput - 22px because label has height = 22px */
  --heightItemFull: 60px;
  --heightItemHalf: 30px;
  --box1Width: 13%;
  --box2Width: 25%;
  --box3Width: 26%;
  --box4Width: 25%;
  --box5Width: 11%;
  --lineHeightLabel: 24px;
}

.font-14 {
  font-size: var(--fontSize1);
}
.font-16 {
  font-size: var(--fontSize2);
}
.font-18 {
  font-size: var(--fontSize3);
}
.font-20 {
  font-size: var(--fontSize4);
}
.font-22 {
  font-size: var(--fontSize5);
}
.font-23 {
  font-size: var(--fontSize6);
}
.font-26 {
  font-size: var(--fontSize7);
}
.font-28 {
  font-size: var(--fontSize8);
}
.font-30 {
  font-size: var(--fontSize9);
}
.font-32 {
  font-size: var(--fontSize10);
}

a {
  color: #252525;
}
td select,
option {
  text-align: center;
  text-align-last: center;
}

.dotted {
  border: 1px dotted rgb(48, 45, 45);
}
.solid {
  border: 1px solid rgb(48, 45, 45);
}
.border-radius-8 {
  border-radius: 8px;
}
.border-radius-18 {
  border-radius: 18px;
}
.border-radius-20 {
  border-radius: 18px;
  border: 1px solid rgb(201, 194, 194);
}
.border-radius-24 {
  border-radius: 24px;
}
.border-radius-32 {
  border-radius: 32px;
}
.border-radius-36 {
  border-radius: 36px;
}
.border-right {
  border-right: 1px solid rgb(48, 45, 45);
}
.border-left {
  border-left: 1px solid rgb(48, 45, 45);
}
.border-bottom {
  border-bottom: 1px solid black;
}
.border-right-dotted {
  border-right: 1px dotted black;
}
.min-height-page {
  min-height: calc(100vh - 160px);
}
.bg-liner-blue {
  background: linear-gradient(333deg, #b4d2ec, transparent);
}
.bg-liner-blue {
  background: linear-gradient(180deg, #e7f4ff 0%, #b4d2ec 100%);
}
.bg-liner-yellow {
  background: linear-gradient(333deg, #ffe28b, transparent);
}
.bg-liner-orange {
  background: linear-gradient(333deg, #f7c09b, transparent);
}
.bg-light-orange {
  background: #f7c09b;
}
.bg-light-yellow {
  background: #ffe28b;
}
.bg-light-blue {
  background: #83eaf5;
}
input {
  background: transparent;
  font-size: var(--fontSize6);
  border: 1px solid black;
  height: 100%;
}

.label-total {
  width: 100%;
  height: 100%;
  background: var(--bgOrange);
}
.label-caculation {
  width: 100%;
  height: 100%;
  background: var(--bgLightGreen);
}
.label-planned {
  width: 100%;
  height: 100%;
  background: var(--bgLightBlue);
}
.label-manage {
  width: 100%;
  height: 100%;
  background: var(--bgManage);
}
.label-actual-mesure {
  width: 100%;
  height: 100%;
  background: var(--bgOrange);
}
.line-height-24 {
  line-height: 24px;
}

/* layout */
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: center;
  align-items: end;
}
.w-33 {
  width: 33%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90% !important;
}

.h-60 {
  height: 60px;
}
.h-40 {
  height: 40px;
}
.modal-lg .modal-dialog {
  max-width: 80vw !important;
}
