body {
  padding: 0;
  margin: 0;
  color: #252525;
}
.planned {
  background: #8dd1f9 !important;
}
.input-type {
  background: var(--bgInput) !important;
}
.input-bg-white {
  background: #ffffff;
}
.input-auto-calculate {
  background: rgb(144, 214, 144) !important;
}
.background-gray {
  background: var(--bgDisabled) !important;
}
.bg-pink {
  background: rgb(255 153 204);
}

.light-blue {
  background: #8dd1f9;
}

.td-blue {
  background: #94d0df !important;
}

#cb-span {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
#cb-span label {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
#cb-span input {
  display: block;
}

input.cb + label::after {
  visibility: visible;
  content: "";
  height: 100%;
  display: block;
}

.checked + label::after {
  content: "";
  background: url("/public/assets/icons/check.png") no-repeat center;
  background-size: 26px;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table td {
  border: 1px solid #252525;
  white-space: pre-line;
  font-size: var(--fontSize5);
  margin: 0;
  padding: 0 !important;
  height: 40px;
  vertical-align: middle;
  text-align: center;
  line-height: initial;
}
.table-dotted td {
  border: 1px dotted #252525;
}
.table-gray-title-col tr:first-child td {
  background: var(--lightGray);
  border: none;
}
@media only screen and (max-width: 800px) {
  .table td {
    font-size: var(--fontSize5);
    line-height: initial;
  }
}
.table th {
  border: 1px dotted #252525;
  white-space: pre-line;
  padding: 0px !important;
  font-size: var(--fontSize5);
  text-align: center;
  color: #252525;
}
.react-datepicker-wrapper input {
  background: transparent;
}
.table-responsive {
  overflow: auto;
}
.row {
  margin-right: 0 !important;
  padding-right: 0 !important;
}
.react-datepicker-wrapper {
  height: 100%;
}
.react-datepicker__input-container {
  height: 100%;
}
.modal-footer {
  justify-content: center !important;
}
.hTitle2 {
  border: 1px solid;
  padding: 2px 12px;
  background: linear-gradient(to top, #b3d1ec, transparent);
}
.b-none {
  border: none !important;
}

/* picked mobile */
.picker-container .picker-column .picker-item.picker-item-selected {
  color: #ffffff !important;
  font-size: 46px;
  font-family: "yu-gothic-bold";
  font-weight: 500;
}
.picker-container .picker-column .picker-item {
  color: white !important;
  font-size: 26px;
}
.p-content {
  padding: 16px;
}
.ck-content {
  min-height: 300px !important;
}
.modal-content {
  /* max-width: 400px; */
  margin: auto;
}
.hide-td {
  border: none !important;
  background: transparent !important;
}
/* font */
.font-22 {
  font-size: 22px;
}
.box {
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: black;
  border-radius: 8px;
  font-size: 24px;
}
.current-input {
  background: yellow;
}
.box-input {
  min-width: 80px;
  min-height: 36px;
  border-radius: 8px;
}
.border-black {
  border: 1px solid black;
}
.border-dashed {
  border: 1ps dashed black;
}
.layout {
  width: 100%;
  display: flex;
}
.layout-left {
  width: 50%;
}
.layout-right {
  width: 50%;
}

.bg-blue-1 {
  background: #4472c4 !important;
}
.bg-blue-2 {
  background: #cdd4ea !important;
}

.label {
  background: #e7e6e6;
  padding-left: 0.5rem;
  font-size: var(--fontSize5);
  line-height: var(--lineHeightLabel);
}
.label-orange-pink {
  background: linear-gradient(
    180deg,
    #fdf2eb 0%,
    #fadbc7 29.69%,
    #f8cbad 67.71%,
    #f8ccae 100%
  );
  padding-left: 0.5rem;
  font-size: var(--fontSize5);
}
.label-blue {
  background: linear-gradient(to bottom, #aabee4 10%, #aabee4, #aabee4 10%);
  padding-left: 0.5rem;
  font-size: var(--fontSize5);
}
.label-gray {
  background: linear-gradient(to bottom, #949494 10%, #c2c1c1, #cac7c7 10%);
  padding-left: 0.5rem;
  font-size: var(--fontSize5);
  line-height: var(--lineHeightLabel);
}
.label-yellow {
  background: #ffff99;
  padding-left: 0.5rem;
  font-size: var(--fontSize5);
  line-height: var(--lineHeightLabel);
}
.label-tag {
  height: 100%;
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  background: #ffe28c;
}

.manageType {
  background: var(--bgManage);
}
.planned-type {
  background: var(--bgPlanned);
}

.accordion-body {
  padding: 0 !important;
}
button.accordion-button {
  font-size: 1.5rem;
  padding: 0.5rem;
}
.accordion-header {
  position: relative;
  z-index: 3;
}
.disabled {
  background: var(--bgDisabled);
  pointer-events: none;
}

.status-OK {
  display: flex;
  background: #69ffff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  font-size: 14px;
}
.status-NG {
  display: flex;
  background: var(--bgNG);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  font-size: 14px;
  color: #fff;
}
.status-NG-Radius {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-align: center;
  background: var(--bgManage);
}
.drag-here {
  background: transparent;
  color: transparent;
}
.position-initial {
  position: initial !important;
}

div[aria-disabled="true"] {
  background: var(--bgDisabled);
}

.modal-confirm .modal-footer {
  border-top: 0;
}
.modal-confirm .modal-header {
  border-bottom: 0;
}

.card-header {
  background: linear-gradient(180deg, #e7f4ff 0%, #b4d2ec 100%);
  z-index: 2;
}
.card-body {
  padding: 0 !important;
}

.error {
  color: red !important;
}
